<template>
  <div class="contain" :style="eventInfo.playType===1?'overflow: hidden;':''" id="review_box" @mouseover="rollStop()"
       @mouseout="rollStart(60)">
    <div id="comment1" class="contain-banner" v-if="showList.indexOf(1)!==-1">
      <img :src="eventInfo.eventCover" alt="">
    </div>
    <div id="comment2" class="contain-swiper" v-if="showList.indexOf(2)!==-1">
      <div class="swiper-header" >裁判介绍</div>
      <div class="swiper-center">
        <div class="center-title" style="font-size:  var(--theme_font-size);">裁判简介</div>
        <div class="center-body">
          <img v-if="judgeList[index].avatarUrl" :src="judgeList[index].avatarUrl">
          <div class="body-text">
            <span style="font-size:  var(--theme_font-size);"> 姓名: {{ judgeList[index].name }}</span>
            <span style="font-size:  var(--theme_font-size);">{{ judgeList[index].descContent ? judgeList[index].descContent : '暂无简介' }}</span>
          </div>
        </div>
      </div>
      <div class="contain-left" @click="changeJudge(1)"></div>
      <div class="contain-right" @click="changeJudge(0)"></div>

    </div>

    <div id="comment3" class="contain-swiper" v-show="showList.indexOf(3)!==-1">
      <div class="swiper-header">参赛选手名单</div>
      <div class="swiper-center" >
        <div class="center-title">参赛选手名单</div>
        <div class="content-student" id="scrollArea" >
          <div class="student-box" v-for="(item,index) in playerList" :key="item.id">
            <img v-if="item.avatarUrl" :src="item.avatarUrl" alt="">
            <i v-if="item.avatarUrl" style="color: var(--theme_font-color);font-size:  var(--theme_font-size);">{{item.name}}</i>
            <span v-if="!item.avatarUrl" class="active" >
              <i style="font-size:  var(--theme_font-size);font-weight: bold"> {{ item.name }}</i>
              <i style="color: var(--theme_font-color);font-size:  var(--theme_font-size);">{{index + 1}}</i>
            </span>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {findById} from "../../api/event";

export default {
  name: "beforeShow",
  data() {
    return {
      showList: [],
      eventInfo: {},
      timer: null,
      roleFlag: null,
      playerList: [],
      judgeList: [],
      index: 0
    }
  },
  sockets: {
    // 连接成功
    onopen() {
      console.log("连接websocket成功");
    },
    // 接收消息
    onmessage(e) {
      this.getEventInfo(this.eventInfo.id)
    },
    // 连接报错
    onerror() {
      console.log("连接websocket出错");
    },
    // 关闭连接
    onclose() {
      console.log("websocket关闭");
    },
  },
  created() {
    this.eventInfo = JSON.parse(sessionStorage.getItem('event'))
    this.getEventInfo(this.eventInfo.id)
  },
  mounted() {
    //1管理 2评委 3主持 4大屏端
    this.roleFlag = sessionStorage.getItem('roleFlag');
    let scrollArea = document.getElementById("scrollArea");
    setInterval(function() {
      // 每次滚动的距离，可以根据需求进行调整
      scrollArea.scrollTop += 3;
      const scrollTop = scrollArea.scrollTop; // 滚动条顶部到可见区域顶部的距离
      const offsetHeight = scrollArea.offsetHeight; // 可见区域的高度
      const scrollHeight = scrollArea.scrollHeight; // 盒子总高度
      if (scrollTop + offsetHeight >= scrollHeight) {
        scrollArea.scrollTop  = 0;
      }
    }, 200);
    
   
  },
  methods: {
    changeJudge(type) {
      if (type === 1) {
        //切换上一个
        if (this.index === 0) {
          this.index = this.judgeList.length - 1
        } else {
          this.index = this.index - 1
        }
      } else {
        if (this.index === this.judgeList.length - 1) {
          this.index = 0
        } else {
          this.index = this.index + 1
        }
      }
    },
    //查询比赛信息
    async getEventInfo(id) {
      const {data} = await findById(id);
      if (data.code === 200) {
        this.eventInfo = data.data
        this.showList = data.data.beforeShowList
        this.playerList = data.data.playerList
        this.judgeList = data.data.judgeList
        if (this.roleFlag === '4' && this.eventInfo.startStatus === 1 && this.eventInfo.playType === 1) {
          this.roll(60);
        }
        if (this.roleFlag === '3') {
          if (this.eventInfo.startStatus === 2 && this.$route.path !== '/drawControl') {
            this.$router.push('/drawControl')
          }
          if (this.eventInfo.startStatus === 1 && this.$route.path !== '/hostPage') {
            this.$router.push('/hostPage')
          }
          if (this.eventInfo.startStatus === 3 && this.$route.path !== '/eventControl') {
            this.$router.push('/eventControl')
          }
          if (this.eventInfo.startStatus === 4 && this.$route.path !== '/eventOver') {
            this.$router.push('/eventOver')
          }
        }
        if (this.roleFlag === '4') {
          if (this.eventInfo.startStatus === 1 && this.$route.path !== '/beforeShow') {
            await this.$router.push('/beforeShow')
          }
          if (this.eventInfo.startStatus === 2 && this.$route.path !== '/drawSort') {
            await this.$router.push('/drawSort')
          }
          if (this.eventInfo.startStatus === 3 && this.$route.path !== '/eventShow') {
            await this.$router.push('/eventShow')
          }
          if (this.eventInfo.startStatus === 4 && this.$route.path !== '/showOver') {
            await this.$router.push('/showOver')
          }
        }

      }
    },

    roll(t) {
      // let ul1 = document.getElementById("comment1");
      let ulbox = document.getElementById("review_box");
      // ul2.innerHTML = ul1.innerHTML;
      ulbox.scrollTop = 0;
      this.rollStart(t);
    },
    rollStart(t) {
      if (this.roleFlag === '4' && this.eventInfo.startStatus === 1 && this.eventInfo.playType === 1) {
        let ul1 = document.getElementById("comment1");
        let ul2 = document.getElementById("comment2");
        let ul3 = document.getElementById("comment3");
        let ulbox = document.getElementById("review_box");
        this.rollStop();
        let n = 0;
        this.timer = setInterval(() => {
          if (ulbox.scrollTop && ulbox.scrollTop !== 0 && ulbox.scrollTop === n) {
            ulbox.scrollTop = 0;
            n = 0;
          } else {
            n = ulbox.scrollTop;
            ulbox.scrollTop += 5;
          }
          // // 当滚动高度大于列表内容高度时恢复为0
          // if (ulbox.scrollTop >= (ul1.scrollHeight + ul2.scrollHeight + ul3.scrollHeight)) {
          //     ulbox.scrollTop = 0;
          // } else {
          //     ulbox.scrollTop+=10;
          // }
        }, t);
      }

    },
    rollStop() {
      clearInterval(this.timer);
    },


  },
  beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  },
}
</script>

<style scoped lang="less">
.contain {
  position: relative;
  width: 100%;
  height: 100%;
  /*overflow: hidden; !* 必须 *!*/
  background-color: var( --theme_bg-color);
  .contain-banner {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .contain-swiper {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat  no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    background-color: var(--theme_bg-color);
    background-image:var(--theme_bg-img) !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    .swiper-header {
      width: 40%;
      height: 80px;
      box-sizing: border-box;
      margin-bottom: 30px;
      background: url("../../assets/images/new1.png") no-repeat center;
      background-size: contain;
      text-align: center;
      line-height: 80px;
      color: var(--theme_font-color);
      font-size: 45px;
      font-weight: bold;
    }

    .swiper-center {
      width: 80%;
      height: calc(100% - 200px);
      /*background-color: red;*/
      background: url("../../assets/images/new2.png") no-repeat;
      background-size: 100% 100%;
      padding: 3%;

      .center-title {
        width: 100%;
        color: var(--theme_font-color);
        height: 100px;
        font-size: 30px;
        line-height: 100px;
        font-family: "楷体";
        font-weight: bold;
      }

      .content-student {
        width: 100%;
        height: calc(100% - 100px);
        overflow: auto;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;

        .student-box {
          /*width: 00px;*/
          /*height: 200px;*/
          /*background-color: red;*/
          margin: 0 0 50px 0;
          padding: 20px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;

          img {
            width: 70%;
            height: 70%;
            border-radius: 50%;
          }
          .active{
            width: 70%;
            height: 70%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            font-size:  var(--theme_font-size);
            color: #f7f01b;
            font-family: "楷体";
          }
          .text-box {
            width: 100%;
            height: 30%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            color: var(--theme_font-color);
            font-family: "楷体";
          }
        }
      }

      .center-body {
        margin-top: 20px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
        }

        .body-text {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          color: var(--theme_font-color);
          font-family: "楷体";
          font-weight: bold;
          span {
            display: inline-block;
            width: 100%;
            margin-bottom: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .contain-left {
      position: absolute;
      left: 5%;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 50px;
      background: url("../../assets/images/left.png") no-repeat;
      background-size: 100% 100%;
      background-attachment: fixed;
      cursor: pointer;
    }

    .contain-right {
      position: absolute;
      right: 5%;
      top: 50%;
      transform: translateY(-50%);
      width: 20px;
      height: 50px;
      background: url("../../assets/images/right.png") no-repeat;
      background-size: 100% 100%;
      background-attachment: fixed;
      cursor: pointer;

    }
  }
}
</style>
